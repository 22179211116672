import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../Logo.png';

function Header() {
  return (
    <header className="header">
        <Link aria-hidden="true" to="/home"><img src={logo} alt=""/></Link>
        <span><Link to="/home">Poddy</Link></span>
        <nav>
            <ul>
                <li>
                    <Link to="/home" title="Home">Home</Link>
                </li>
                {/* <li>
                    <Link to="/releases">Release Notes</Link>
                </li> */}
                <li>
                    <Link to="/contact">Contact</Link>
                </li>
            </ul>
        </nav>
    </header>
  );
}

export default Header;
