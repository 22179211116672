import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';

import Home from './pages/Home';
import Releases from './pages/Releases';
import Contact from './pages/Contact';
import Header from './components/Header';
import Helmet from 'react-helmet';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Route exact path="/" component={Home}/>
        <Route exact path="/home" component={Home}/>
        <Route exact path="/releases" component={Releases}/>
        <Route exact path="/contact" component={Contact}/>
      </div>
    </Router>
  );
}

export default App;
