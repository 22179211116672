import React from 'react';
import '../styles/Home.css';

import screen1 from '../images/screen1.png';
import screen2 from '../images/screen2.png';
import screen3 from '../images/screen3.png';
import screen4 from '../images/screen4.png';
import mockup2 from '../images/Empty_Mockup.png';
import Slideshow from '../components/Slideshow';

function Home() {
  return (
    <main className="home">
        <div className="container">
            <div className="textWrap">
                <div className="titleWrap">
                    <h1>Poddy</h1>
                    <p>podcasts, but lighter</p>
                </div>
                <a className="galaxy" href="https://galaxy.store/Poddy"><img src="https://img.samsungapps.com/seller/images/badges/galaxyStore/png_big/GalaxyStore_English.png" alt="Available on Samsung Galaxy Store"/></a>
            </div>
            <Slideshow images={[screen1, screen2, screen3, screen4]} containerImage={mockup2}/>
            <a className="galaxy-bottom" title="Available on Samsung Galaxy Store" href="https://galaxy.store/Poddy"><img src="https://img.samsungapps.com/seller/images/badges/galaxyStore/png_big/GalaxyStore_English.png" alt="Available on Samsung Galaxy Store"/></a>
        </div>
        <div className="footer">
          <p>Eighty Kesa Ltd</p>
          <p>Company Number: 12913460</p>
        </div>
    </main>
  );
}

export default Home;
