import React from 'react';

function Releases() {
  return (
    <article className="releases">
        <p>I am release</p>
    </article>
  );
}

export default Releases;
