import React from 'react';
import "../styles/Contact.css";

function Contact() {
  return (
    <main className="contact">
        <div class="contact-wrapper">
          <h1 aria-hidden="true">Contact</h1>
          <p>For any questions, problems or feature requests, contact me using any of the options below</p>
          <ul>
              <li>Email: <a href="mailto:support@poddy.app">support@poddy.app</a></li>
              <li>Twitter: <a href="https://twitter.com/SudoSleep">@SudoSleep</a></li>
          </ul>
        </div>
    </main>
  );
}

export default Contact;
