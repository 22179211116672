import React from 'react';
import './Slideshow.css';

class Slideshow extends React.Component {

    state = {
        currentIndex: 0,
        intervalId: null
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var intId = setInterval(() => {
            this.nextItem();
        }, 3000);
        this.setState({ intervalId: intId });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    nextItem() {
        const nextIndex = this.state.currentIndex === this.props.images.length-1 ? 0 : this.state.currentIndex+1;
        this.setState({ currentIndex: nextIndex })
    }

    render() {
        const getStr = (index) => {
            const baseStr = {
                transition: 'all .4s',
                opacity: 0
            }

            if (this.state.currentIndex === index)
            {
                baseStr.opacity = 1;
            }
            else
            {
                baseStr.opacity = 0;
            }
            return baseStr;
        }

        const { containerImage, images } = this.props;
        const renderedImages = images.map((im, i) => <li style={getStr(i)} key={i} className="imageItem"><img src={im} alt=""/></li>).filter((im, i) => i === this.state.currentIndex || i === this.state.currentIndex+1);
        return(
        <div className="promoSlideshow">
            <div className="renderedImages">
                <ul>{renderedImages}</ul>
            </div>
            <img className="containerImage" src={containerImage} alt=""/>
        </div>)
    }
}

export default Slideshow;
